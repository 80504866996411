<template>
  <div class="offer-info-block">
    <validation-observer
      ref="main_form"
      v-slot="{ validated }"
    >
      <b-row>
        <b-col
          cols="12"
          style="background: #fff"
        >
          <b-row style="padding-bottom: 0.857rem;">
            <b-col cols="12">
              <label>Your postback URL</label>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                name="Postback URL"
                :rules="{ required: is_resend_failed_webhooks === 1 || is_postback_on_reconciliations === 1 }"
              >
                <b-input
                  v-model="postback_url"
                />
                <small
                  v-if="(is_resend_failed_webhooks === 1 || is_postback_on_reconciliations === 1) && validated && errors.length > 0"
                  class="text-danger"
                >{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col cols="12">
              <div class="offer-info-block-des">
                Your Server-to-Server webhook url to process user
                rewards on your frontend.
                This url will be called on each reward and screenout
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          style="background: #fff"
          cols="6"
        >
          <b-form-checkbox
            v-model="is_resend_failed_webhooks"
            class="mr-1"
            :value="1"
            :unchecked-value="0"
          >
            <div class="mx-50">
              <b>Resend failed webhooks (Recommended)</b><br>
              If your server responds with a non-success code (other than 2xx), we will retry the postback call later.

              <div class="block-warning-unique-id font-weight-bold mt-50">
                You must ensure that you process each (tx_id) only once! Otherwise you might process transactions twice!
              </div>
            </div>
          </b-form-checkbox>

          <b-form-checkbox
            v-model="is_postback_on_reconciliations"
            class="mr-1 mt-2"
            :value="1"
            :unchecked-value="0"
          >
            <div class="mx-50">
              <b>Reconciliation webhooks</b><br>
              When our clients reject a complete, we will fire a webhook for the complete with negative values for
              <b>{reward}</b> and <b>{payout}</b>.<br>
              The <b>{tx_id}</b> will be new as every change of balance is considered a new transaction.
            </div>

          </b-form-checkbox>

          <div class="block-attention mb-2">
            Our postback calls will come from the following IP addresses. We recommend only accepting calls from these
            IPs.
            <b-form-textarea
              :value="`168.119.57.82\n49.12.33.196\n49.13.14.251`"
              readonly
              rows="3"
              class="bg-white w-50 text-right"
            />
          </div>
        </b-col>
        <b-col
          cols="6"
          style="background: #fff"
        >
          <b-row style="padding-bottom: 0.857rem;">
            <b-col cols="12">
              <label>Available parameters</label>
            </b-col>
          </b-row>
          <table class="table b-table table-striped table-hover border">
            <thead>
              <tr>
                <th>Parameter</th>
                <th>Type</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody role="rowgroup">
              <tr
                v-for="(tr, indextr) in supportedParameters"
                :key="indextr"
                @click="addParameter(tr)"
              >
                <td style="cursor: pointer">
                  {{ supportedParameters[indextr].key }}
                </td>
                <td>{{ supportedParameters[indextr].type }}</td>
                <td>
                  {{ supportedParameters[indextr].description }}
                </td>
              </tr>
            </tbody>
          </table>

        </b-col>
        <b-col
          cols="6"
          class="py-2 px-3"
        >
          <div
            style="font-weight: 600; font-size: 1.143rem; line-height: 1.429rem;"
            class="mb-2"
          >
            Postback Test
          </div>
          <b-row class="mb-2">
            <b-col cols="6">
              <label for="test_unique_user_id">Unique user id</label>
              <b-form-input
                id="test_unique_user_id"
                v-model="test_unique_user_id"
                placeholder="Unique user id"
              />
            </b-col>
            <b-col cols="6">
              <label for="test_amount">Amount</label>
              <b-form-input
                id="test_amount"
                v-model="test_amount"
                placeholder="Amount"
                type="number"
                :min="test_click_type === 4 ? -1000 : 0"
              />
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col cols="6">
              <label for="userRewardAmount">User reward</label>
              <b-form-input
                id="userRewardAmount"
                :value="userRewardAmount"
                type="number"
                readonly
                placeholder="User reward"
              />
              <div style="font-size: 0.857rem; line-height: 1.143rem; margin-top: 0.857rem">
                According to "Currency conversion rate"
              </div>
            </b-col>
            <b-col cols="6">
              <label>Type</label>
              <b-radio-group
                v-model="test_click_type"
                style="margin-top: 0.429rem; gap: 10px; display: grid;"
                @change="onClickTypeChange"
              >
                <b-radio :value="1">
                  Complete
                </b-radio>
                <b-radio :value="2">
                  Screenout
                </b-radio>
                <b-radio :value="3">
                  Bonus
                </b-radio>
                <b-radio :value="4">
                  Reconciliation
                </b-radio>
              </b-radio-group>
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col cols="12">
              <b-button
                variant="primary"
                @click="test_postback()"
              >
                Test Postback
              </b-button>
            </b-col>
          </b-row>
          <div style="font-weight: 600; font-size: 1.143rem; line-height: 1.429rem; margin: 1.714rem 0">
            Postback Results
          </div>
          <b-row>
            <b-col cols="8">
              <label style="margin-bottom: 0.857rem">Postback url fired</label>
              <b-input
                v-model="test_fired_url"
                readonly="readonly"
                style="background-color: #FFF; margin-bottom: 0.857rem;"
              />
              <div>{{ test_description }}</div>
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col cols="12">
              <div
                v-if="test_status_code >= 0"
                style="text-align: center"
                :class="test_status_class"
              >
                <div class="w-full">
                  <b style="font-size: 1.286rem">{{ test_status_code }}</b>
                </div>
                <div
                  v-if="test_status_text != ''"
                  class="w-full"
                >
                  {{ test_status_text }}
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>

        <b-col
          cols="6"
          style="background: #fff"
        />
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full'

export default {
  components: { ValidationObserver, ValidationProvider },
  props: ['value'],
  data() {
    return {
      postback_url: '',
      is_resend_failed_webhooks: this.value.is_resend_failed_webhooks,
      is_postback_on_reconciliations: this.value.is_postback_on_reconciliations,
      mounted: false,
      supportedParameters: [
        {
          key: '{reward}',
          type: 'Integer',
          description:
              'The amount of in-app currency that should be rewarded to the user',
        },
        {
          key: '{payout}',
          type: 'Integer',
          description: 'Publisher Payout in USD',
        },
        {
          key: '{type}',
          type: 'Integer',
          description: '1 = complete, 2 = screenout, 3 = bonus, 4 = reconciliation',
        },
        {
          key: '{user}',
          type: 'String',
          description: 'Unique User Id',
        },
        {
          key: '{tx_id}',
          type: 'String',
          description: 'Unique Transaction ID',
        },
        {
          key: '{subid}',
          type: 'String',
          description: 'Returns your subid',
        },
      ],
      test_unique_user_id: null,
      test_amount: null,
      test_user_reward: null,
      test_click_type: 1,
      test_status_class: null,
      test_status_code: -1,
      test_status_text: null,
      test_fired_url: '',
    }
  },
  computed: {
    postback_url_fired() {
      let postbackUrlFired = ''
      postbackUrlFired += this.postback_url
      const userUniqueId = this.test_unique_user_id === null
      || this.test_unique_user_id.trim() === ''
        ? ''
        : encodeURIComponent(this.test_unique_user_id.trim())
      postbackUrlFired = postbackUrlFired.replaceAll(
        '{user}',
        userUniqueId,
      )
      let testAmount = this.test_amount === null ? 0 : parseFloat(this.test_amount)
      if (Number.isNaN(testAmount) || testAmount < 0) {
        testAmount = 0.0
      }
      testAmount = testAmount.toFixed(2)
      postbackUrlFired = postbackUrlFired.replaceAll(
        '{payout}',
        encodeURIComponent(this.test_amount),
      )
      postbackUrlFired = postbackUrlFired.replaceAll(
        '{reward}',
        encodeURIComponent(this.userRewardAmount),
      )
      postbackUrlFired = postbackUrlFired.replaceAll(
        '{type}',
        encodeURIComponent(this.test_click_type),
      )
      return postbackUrlFired
    },
    userRewardAmount() {
      let testAmount = this.test_amount === null ? 0 : parseFloat(this.test_amount)

      // Ensure testAmount is valid
      if (Number.isNaN(testAmount)) {
        testAmount = 0.0
      }

      const rewardAmount = this.value.currency_exchange_rate === null
        ? 0
        : parseInt(parseFloat(testAmount) * parseInt(this.value.currency_exchange_rate))

      // If the reward amount is NaN, or the rate is invalid, return 0
      if (Number.isNaN(rewardAmount)) {
        return 0
      }

      // If "Reconciliation" is selected, make the reward negative
      if (this.test_click_type === 4) {
        return -Math.abs(rewardAmount) // Ensure negative reward for Reconciliation
      }

      // For other cases, return the calculated reward, allowing positive values only
      return rewardAmount > 0 ? rewardAmount : 0
    },
    test_description() {
      if (this.test_status_code >= 0) {
        return 'Result server code'
      }
      return 'Click test postback to check result'
    },
  },
  watch: {
    postback_url() {
      const appData = this.value
      appData.postback_url = this.postback_url
      this.$emit('input', appData)
    },
    is_resend_failed_webhooks() {
      const appData = this.value
      appData.is_resend_failed_webhooks = this.is_resend_failed_webhooks
      this.$emit('input', appData)
    },
    is_postback_on_reconciliations() {
      const appData = this.value
      appData.is_postback_on_reconciliations = this.is_postback_on_reconciliations
      this.$emit('input', appData)
    },
    'value.postback_url': function () {
      this.init_data()
    },
    test_amount(newAmount) {
      // Force the test_amount to be negative when "Reconciliation" is selected
      if (this.test_click_type === 4 && newAmount > 0) {
        this.test_amount = -Math.abs(newAmount)
      }
    },
  },
  mounted() {
    this.init_data()
    this.mounted = true
  },
  methods: {
    onClickTypeChange() {
      // If Reconciliation is selected, make the amounts negative
      if (this.test_click_type === 4) {
        if (this.test_amount > 0) {
          this.test_amount = -Math.abs(this.test_amount) // Ensure negative amount
        }
      } else {
        // Reset amounts to positive if not Reconciliation
        this.test_amount = Math.abs(this.test_amount)
      }
    },
    reset_data() {
      this.$emit('reset', ['postback_url'])
    },
    init_data() {
      this.postback_url = this.value.postback_url
      if (this.postback_url === null) this.postback_url = ''
      this.is_resend_failed_webhooks = parseInt(this.value.is_resend_failed_webhooks)
      this.is_postback_on_reconciliations = parseInt(this.value.is_postback_on_reconciliations)
    },
    test_postback() {
      if (
        (this.postback_url.indexOf('http://') !== 0
              && this.postback_url.indexOf('https://') !== 0)
      ) {
        this.alertError('Invalid url')
        return
      }
      this.showLoading()
      this.$http.post('/api/apps/test-postback', {
        url: this.postback_url_fired,
      }).then(response => {
        this.hideLoading()
        const returnData = response.data
        this.test_status_code = returnData.code
        this.test_status_text = ''
        if (returnData.code === 200) {
          this.test_status_class = 'text-success'
          this.test_status_text = 'Success'
        } else if (returnData.code < 400) {
          this.test_status_class = 'text-warning'
        } else {
          this.test_status_class = 'text-danger'
        }
        this.test_fired_url = this.postback_url_fired
      }).catch(errors => {
        this.hideLoading()
        this.alertError(errors.response.data)
      })
    },
    addParameter(param) {
      this.postback_url += param.key
    },
  },
}
</script>

<style scoped lang="scss">
.vs-dialog-text {
  overflow: auto;
  max-height: 14.286rem;
  word-break: break-all;
}

.vs-con-textarea {
  margin-bottom: 0.5rem;
}

.offer-info-block {
  background: #F8F8F8;
  border-radius: 0.429rem;
  margin-bottom: 0.857rem;

  label {
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 100%;
    color: #5E5873;
  }
}

.offer-info-block-des {
  font-style: normal;
  font-weight: normal;
  font-size: 0.857rem;
  line-height: 1.143rem;
  color: #6E6B7B;
  margin-top: 0.857rem;
  margin-bottom: 2.286rem;
}

.block-warning-unique-id {
  position: relative;
  background: rgba(234, 84, 85, 0.12);
  border-radius: 0.429rem;
  font-weight: 500;
  font-size: 1rem;
  line-height: 120%;
  color: #EA5455;
  padding: 0.857rem 1.214rem;
}

.block-attention {
  margin-top: 1.143rem;
  padding: 1.143rem;
  background: rgba(255, 159, 67, 0.12);
  border-radius: 0.429rem;
  font-size: 1rem;
  line-height: 140%;
}
</style>
