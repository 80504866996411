<template>
  <div class="app-edit-general">
    <validation-observer
      ref="main_form"
      v-slot="{ validated }"
    >
      <b-row>
        <b-col
          cols="6"
          style="padding-right: 4.714rem;padding-left: 2rem;"
        >
          <b-row style="margin-bottom: 1.714rem">
            <b-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                name="app name"
                :rules="{ required: true }"
              >
                <label for="input-app-name">App name</label>
                <b-input
                  id="input-app-name"
                  v-model="app_name"
                />
                <small
                  v-if="validated && errors.length > 0"
                  class="text-danger"
                >{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                name="app url"
                :rules="{required: true, url: true}"
              >
                <label for="input-app-url">App url</label>
                <b-input
                  id="input-app-url"
                  v-model="app_url"
                  name="app_url"
                />
                <small
                  v-if="validated && errors.length > 0"
                  class="text-danger"
                >{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="6">
          <b-row class="mb-1">
            <b-col
              cols="12"
              style="display:flex;"
            >
              <div style="display: inline-block; margin-right: 1.571rem;">
                <label>Status</label>
              </div>
              <div
                v-if="isLiveMode"
                class="app-status"
              >
                <div>
                  <b-badge variant="light-success">
                    Live
                  </b-badge>
                </div>
                <div style="clear: both" />
                <small>
                  Your app is live
                </small>
              </div>
              <div
                v-if="isTestMode"
                class="app-status"
              >
                <div style="width: 100%; clear: both;">
                  <b-badge variant="light-warning">
                    Test
                  </b-badge>
                </div>
                <div style="clear:both" />
                <div style="padding-top: 0.357rem;">
                  <b-button
                    id="go-live-btn-small"
                    :variant="btnGoliveVariant"
                    size="sm"
                    @click="requestToMakeAppLive()"
                  >
                    Request to go live
                  </b-button>
                  <b-tooltip
                    v-if="btnGoliveVariant === 'secondary'"
                    target="go-live-btn-small"
                    placement="bottom"
                    triggers="hover"
                  >
                    Please complete the app setup first
                  </b-tooltip>
                </div>
                <div
                  style="font-weight: 500; font-size: 0.857rem; line-height: 1.143rem; color: #6E6B7B; padding-top: 0.5rem;"
                >
                  Before an app goes live for the first time we<br>
                  review the integration to make sure the<br>
                  setup is working correctly
                </div>
              </div>
              <div
                v-if="isInApproval"
                class="app-status"
              >
                <div>
                  <b-badge variant="light-primary">
                    In Approval
                  </b-badge>
                </div>
                <div style="clear:both" />
                <div
                  style="font-weight: 500; font-size: 0.857rem; line-height: 1.143rem; color: #6E6B7B; padding-top: 0.5rem;"
                >
                  We are currently checking your integration, we will get back to you shortly
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row
            v-if="isLiveMode"
            class="mb-1"
          >
            <b-col cols="3">
              <div style="clear:both;">
                Test mode
              </div>
              <small>Shows you dummy surveys to test your integration. Your users will continue to see live
                surveys.</small>
            </b-col>
            <b-col cols="9">
              <div
                class="form-inline"
                style="height: 2.857rem;"
              >
                <b-form-checkbox
                  v-model="test_mode"
                  switch
                  class="mr-1"
                />
                <b-form-radio-group
                  v-show="test_mode"
                  id="test_mode_on"
                  v-model="test_mode_on"
                  name="test_mode_on"
                  class="mr-1"
                >
                  <b-form-radio value="ip">
                    IP
                  </b-form-radio>
                  <b-form-radio value="user">
                    User
                  </b-form-radio>
                </b-form-radio-group>
                <validation-provider
                  #default="{ errors }"
                  name="test mode identify"
                  :rules="test_mode ? {required: true} : ''"
                >
                  <b-input
                    v-show="test_mode"
                    v-model="test_mode_value"
                    name="test_mode_value"
                  />
                  <div style="clear:both" />
                  <small
                    v-if="value.isValidated && errors.length > 0"
                    class="text-danger"
                  >{{ errors[0] }}</small>
                </validation-provider>
              </div>
            </b-col>
          </b-row>
          <b-row
            v-if="isLiveMode"
            class="mb-1"
          >
            <b-col cols="3">
              <div style="clear:both;">
                Security hash
              </div>
              <small>Enables/disables security hash for app integration</small>
            </b-col>
            <b-col cols="9">
              <div
                class="form-inline"
                style="height: 2.857rem;"
              >
                <b-form-checkbox
                  v-model="is_secure"
                  switch
                  class="mr-1"
                  disabled
                />
              </div>

              <b-badge variant="light-warning">
                Contact your account manager to enable security hashing
              </b-badge>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <!--        <b-row>
                  <b-col style="text-align: right;">
                          <b-button
                          variant="outline-secondary"
                          @click="reset_data"
                          >Reset</b-button
                      >
                  </b-col>
              </b-row>-->
    </validation-observer>
  </div>
</template>

<script>
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full'

extend('url', {
  validate(value) {
    return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(value)
  },
  message: 'This value must be a valid URL',
})

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: ['value'],
  data() {
    return {
      app_name: '',
      app_url: '',
      test_mode: 0,
      test_mode_on: 'ip',
      test_mode_value: '',
      status: 0,
      mounted: false,
      is_secure: false,
    }
  },
  computed: {
    disabledLiveMode() {
      return parseInt(this.value.status) !== 1
    },
    watchParentValue() {
      return [
        this.value.app_name,
        this.value.app_url,
        this.value.status,
        this.value.test_mode,
        this.value.test_mode_on,
        this.value.test_mode_value,
        this.value.is_secure,
      ]
    },
    isLiveMode() {
      return parseInt(this.value.status) === 1
    },
    isTestMode() {
      return parseInt(this.value.status) === 0
    },
    isInApproval() {
      return parseInt(this.value.status) === 2
    },
    btnGoliveVariant() {
      let isValidReward = false
      if (this.value.reward_type !== 0) isValidReward = true
      else if (this.value.fixed_reward) isValidReward = true
      if (this.value.currency_exchange_rate && this.value.currency_name && isValidReward) return 'primary'
      return 'secondary'
    },
  },
  watch: {
    $data: {
      handler() {
        if (this.mounted) {
          const appData = this.value
          appData.app_name = this.app_name
          appData.app_url = this.app_url
          appData.status = this.status
          appData.test_mode = this.test_mode ? 1 : 0
          appData.test_mode_on = this.test_mode_on
          appData.test_mode_value = this.test_mode_value
          appData.is_secure = this.is_secure
          this.$emit('input', appData)
        }
      },
      deep: true,
    },
    watchParentValue() {
      this.init_data()
    },
  },
  mounted() {
    this.init_data()
    this.mounted = true
  },
  methods: {
    reset_data() {
      this.$emit('reset', [
        'app_name',
        'app_url',
        'test_mode',
        'status',
        'test_mode_on',
        'test_mode_value',
      ])
    },
    init_data() {
      this.app_name = this.value.app_name
      this.app_url = this.value.app_url
      this.status = this.value.status
      this.test_mode = parseInt(this.value.test_mode) === 1
      this.test_mode_on = this.value.test_mode_on
      this.test_mode_value = this.value.test_mode_value
      if (
        !this.test_mode_value
          && this.test_mode_on === 'ip'
          && this.test_mode
      ) {
        this.test_mode_value = this.value.ip
      }
      this.is_secure = this.value.is_secure
    },
    requestToMakeAppLive() {
      if (this.btnGoliveVariant === 'secondary') return Promise.resolve(false)

      this.showLoading()

      return this.$http
        .post(`/api/app/${this.$route.params.appHash}/request-live`)
        .then(response => {
          this.hideLoading()
          if (response.data.status) {
            this.$emit('request-live', response.data.status)
          }
          return true // Ensure we return a value in every case
        })
        .catch(errors => {
          this.alertError(errors.response.data)
          this.hideLoading()
          return false // Ensure we return a value in every case
        })
    },
  },
}
</script>
<style scoped lang="scss">
.app-status {
  display: inline-block;
}

.app-edit-general {

  label {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 100%;
    color: #5E5873;
    margin-bottom: 0.857rem;
  }

}
</style>
