<template>
  <div
    ref="colorpicker"
    class="input-group color-picker"
  >
    <span style="position: relative; display: inline-block;">
      <span
        style="cursor: pointer; display: inline-block;position: relative;border: 1px solid rgb(132, 130, 130);height: 2.15rem;padding-right: 20px;display: inline-block;position: relative;border: 1px solid #848282;height: 2.15rem;"
        @click="togglePicker()"
      >
        <span
          class="current-color"
          :style="'background-color: ' + (colorValue || 'transparent')"
        />
        <feather-icon
          icon="ChevronDownIcon"
          class="m-0 p-0"
          size="20"
          style="position: absolute; top: 4px;"
        />
      </span>
      <chrome-picker
        v-if="displayPicker"
        :value="colors"
        style="left: 0px; top: 2.15rem;"
        @input="updateFromPicker"
      />
    </span>
  </div>
</template>

<script>
import { Chrome } from 'vue-color'

export default {
  components: {
    'chrome-picker': Chrome,
  },
  props: {
    color: {
      type: String,
      default: '#000000',
    },
    nullable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      colors: {
        hex: this.nullable && !this.color ? '' : '#000000',
      },
      colorValue: '',
      displayPicker: false,
    }
  },
  watch: {
    colorValue(val) {
      if (val || this.nullable) {
        this.updateColors(val)
        this.$emit('input', val)
      }
    },
    color() {
      this.setColor(this.color || (this.nullable ? null : '#000000'))
    },
  },
  mounted() {
    this.setColor(this.color || (this.nullable ? null : '#000000'))
  },
  methods: {
    setColor(color) {
      if (this.nullable && !color) {
        this.colors = {
          hex: '',
        }
        this.colorValue = null
      } else {
        this.updateColors(color)
        this.colorValue = color
      }
    },
    updateColors(color) {
      if (color && color.slice(0, 1) === '#') {
        this.colors = {
          hex: color,
        }
      } else if (color && color.slice(0, 4) === 'rgba') {
        const rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(',')
        // eslint-disable-next-line no-bitwise,radix
        const hex = `#${((1 << 24) + (parseInt(rgba[0]) << 16) + (parseInt(rgba[1]) << 8) + parseInt(rgba[2])).toString(16).slice(1)}`
        this.colors = {
          hex,
          a: rgba[3],
        }
      } else if (this.nullable) {
        this.colors = {
          hex: '',
        }
      }
    },
    showPicker() {
      document.addEventListener('click', this.documentClick)
      this.displayPicker = true
    },
    hidePicker() {
      document.removeEventListener('click', this.documentClick)
      this.displayPicker = false
    },
    togglePicker() {
      if (this.displayPicker) {
        this.hidePicker()
      } else {
        this.showPicker()
      }
    },
    updateFromInput() {
      this.updateColors(this.colorValue)
    },
    updateFromPicker(color) {
      this.colors = color
      if (this.nullable && !color.hex) {
        this.colorValue = null
        // eslint-disable-next-line eqeqeq
      } else if (color.rgba.a == 1) {
        this.colorValue = color.hex
      } else {
        this.colorValue = `rgba(${color.rgba.r}, ${color.rgba.g}, ${color.rgba.b}, ${color.rgba.a})`
      }
    },
    documentClick(e) {
      const el = this.$refs.colorpicker
      const { target } = e
      if (el !== target && !el.contains(target)) {
        this.hidePicker()
      }
    },
  },
}
</script>

<style scoped>
.vc-chrome {
  position: absolute;
  top: 35px;
  right: 0;
  z-index: 9;
}

.current-color {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-color: #000;
  cursor: pointer;
}

</style>
