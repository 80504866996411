<template>
  <div>
    <input
      ref="fileInput"
      type="file"
      class="hidden"
      :accept="accept"
      @change="handleClick"
    >
    <div
      class="cursor-pointer p-1"
      style="text-align: center;border: 2px solid #dae1e7;border-style: dashed; color: #d0d6da;"
      @click="$refs.fileInput.click()"
      @drop="handleDrop"
      @dragover="handleDragover"
      @dragenter="handleDragover"
    >
      <feather-icon
        icon="UploadCloudIcon"
        class="block"
        size="50"
      />
      <div style="clear:both;" />
      <span>Drop File or </span>
      <span
        class="font-medium text-primary"
        @click.stop="$refs.fileInput.click()"
      >Browse</span>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'accept',
  ],
  data() {
    return {
    }
  },
  methods: {
    handleDragover(e) {
      e.stopPropagation()
      e.preventDefault()
      e.dataTransfer.dropEffect = 'copy'
    },
    handleDrop(e) {
      e.stopPropagation()
      e.preventDefault()
      const { files } = e.dataTransfer
      this.$emit('load-file', files)
    },
    handleClick(e) {
      const { files } = e.target
      if (!files.length) return
      this.$emit('load-file', files)
    },
  },
}
</script>
