<template>
  <div id="app-integration">
    <b-card
      v-if="value.status !== 1"
      class="mx-2 block-test-mode"
      no-body
    >
      <b-card-body style="padding: 0.857rem 1.214rem;">
        <div class="text-test-mode">
          <b-img
            :src="require('@/assets/images/icons/fi_info.svg')"
            style="position: absolute; transform: translate(0, -50%); top: 50%; left: 1.214rem; width: 1.214rem;"
          />
          <span style="display: inline-block; margin-left: 2.143rem; line-height: 130%;">
            Your app is test mode.
            <template v-if="value.status === 2">
              <br>Our Team is reviewing your Integration.
            </template>
          </span>
        </div>
        <b-button
          v-if="value && value.status === 0"
          id="go-live-btn"
          :variant="btnGoliveVariant"
          @click="onClickRequestGolive"
        >
          Request to go live
        </b-button>
        <b-tooltip
          v-if="btnGoliveVariant === 'secondary'"
          target="go-live-btn"
          placement="bottom"
          triggers="hover"
        >
          Please complete the app setup first
        </b-tooltip>
      </b-card-body>
    </b-card>
    <b-tabs
      align="center"
      pills
      class="offer-type"
    >
      <b-tab>
        <template #title>
          <b-media no-body>
            <b-media-aside>
              <b-avatar
                size="32"
                variant="light-primary"
              >
                <feather-icon
                  size="13"
                  icon="Share2Icon"
                  class="tab-title-icon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <div class="tab-title-text">
                API Integration
              </div>
              <div
                class="tab-subtitle-text mb-0"
                style="height: 2.429rem"
              >
                Fetch surveys with our API
              </div>
            </b-media-body>
          </b-media>
        </template>
        <div class="detail-offer-wall">
          <div class="d-flex mb-1">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-avatar
                  size="48"
                  variant="light-primary"
                >
                  <feather-icon
                    size="24"
                    icon="Share2Icon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <div class="offer-info-block-title">
                  Integration
                </div>
                <div class="offer-info-block-des">
                  Use our API to fetch, display, and manage surveys for your users.
                </div>
              </b-media-body>
            </b-media>
          </div>
          <hr style="border-top: 1px solid #EBE9F1; margin: 1.143rem 0 1.714rem;">
          <b-row>
            <b-col cols="12">
              <b-row class="mb-1">
                <b-col>
                  <div
                    class="offer-info-block p-1"
                    style="border-left-width: 0.357rem !important;"
                  >
                    <b-media no-body>
                      <b-media-aside class="mr-1">
                        <b-avatar
                          size="32"
                          variant="light-primary"
                        >
                          <feather-icon
                            size="16"
                            icon="ListIcon"
                          />
                        </b-avatar>
                      </b-media-aside>
                      <b-media-body class="my-auto">
                        <div class="offer-info-block-title">
                          Basic Flow
                        </div>
                        <p
                          class="offer-info-block-des"
                          style="width: auto"
                        >
                          This describes the basic flow of how we can integrate with our API for fetching and displaying
                          surveys to users in your application.
                        </p>
                      </b-media-body>
                    </b-media>
                    <div>
                      <div class="ml-1">
                        <div><strong>1. </strong>Request the Prime Surveys API for each user on your backend.
                        </div>
                        <div><strong>2. </strong>The API responds with a list of surveys for each user and a unique
                          Survey URL to start the survey.
                        </div>
                        <div><strong>3. </strong>Display surveys to your users in your own UI.</div>
                        <div><strong>4. </strong>User clicks on a survey and completes it.</div>
                        <div><strong>5. </strong>Prime Surveys fires a webhook URL to inform you about the user to
                          pay.
                        </div>
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-row class="mb-1">
                <b-col>
                  <div
                    class="offer-info-block p-1"
                    style="border-left-width: 0.357rem !important;"
                  >
                    <b-media no-body>
                      <b-media-aside class="mr-1">
                        <b-avatar
                          size="32"
                          variant="light-primary"
                        >
                          <feather-icon
                            size="16"
                            icon="BookIcon"
                          />
                        </b-avatar>
                      </b-media-aside>
                      <b-media-body class="my-auto">
                        <div class="offer-info-block-title">
                          Documentation
                        </div>
                        <p class="offer-info-block-des">
                          Please see our <a
                            target="_blank"
                            href="https://developer.primesurveys.com/docs/surveys/91lugrbyf6r53-surveys"
                          >API Documentation</a> for more details.
                        </p>
                      </b-media-body>
                    </b-media>
                  </div>
                </b-col>
              </b-row>
              <b-row class="mb-1">
                <b-col>
                  <div
                    class="offer-info-block p-1"
                    style="border-left-width: 0.357rem !important;"
                  >
                    <b-media no-body>
                      <b-media-aside class="mr-1">
                        <b-avatar
                          size="32"
                          variant="light-primary"
                        >
                          <feather-icon
                            size="16"
                            icon="KeyIcon"
                          />
                        </b-avatar>
                      </b-media-aside>
                      <b-media-body class="my-auto">
                        <div class="offer-info-block-title">
                          Your API Details
                        </div>
                        <p
                          class="offer-info-block-des"
                          style="width: auto"
                        >
                          These are the credentials and parameters you need to integrate with our API. The API Base URL
                          is the main entry point for all your requests, and your unique App Key is required to
                          authenticate and access the API services.
                        </p>
                      </b-media-body>
                    </b-media>
                    <div>
                      <div
                        class="d-flex align-items-center mb-50"
                        style="gap: 10px"
                      >
                        <strong>API Base URL</strong>
                        <div class="code-copy-block">
                          <div
                            class="button-copy bg-primary"
                            @click="copyText('partners.primesurveys.com')"
                          >
                            <span>Copy</span>
                          </div>
                          <div class="code-content bg-light-primary">
                            partners.primesurveys.com
                          </div>
                        </div>
                      </div>
                      <div
                        class="d-flex align-items-center"
                        style="gap: 10px"
                      >
                        <strong>App Key:</strong>
                        <div class="code-copy-block">
                          <div
                            class="button-copy bg-primary"
                            @click="copyText(`${app_hash}`)"
                          >
                            <span>Copy</span>
                          </div>
                          <div class="code-content bg-light-primary">
                            {{ app_hash }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-row class="mb-1">
                <b-col>
                  <div
                    class="offer-info-block p-1"
                    style="border-left-width: 0.357rem !important;"
                  >
                    <b-media no-body>
                      <b-media-aside class="mr-1">
                        <b-avatar
                          size="32"
                          variant="light-primary"
                        >
                          <feather-icon
                            size="16"
                            icon="HashIcon"
                          />
                        </b-avatar>
                      </b-media-aside>
                      <b-media-body class="my-auto">
                        <div class="offer-info-block-title">
                          Example API Request
                        </div>
                      </b-media-body>
                    </b-media>
                    <div class="code-copy-block mt-2">
                      <div
                        class="button-copy bg-primary"
                        @click="copyText(`https://partners.primesurveys.com/api/surveys?output=API&app=${app_hash}&external_user_id=user-US-1&ip=35.151.72.180&gender=m&zip=32714&birthday=1992-02-19&user_agent=Mozilla%2F5.0 (Macintosh%3B Intel Mac OS X 10_15_7) AppleWebKit%2F537.36 (KHTML%2C like Gecko) Chrome%2F124.0.0.0 Safari%2F537.36`)"
                      >
                        <span>Copy</span>
                      </div>
                      <div class="code-content bg-light-primary">
                        <pre class="code-block">
                            <span
                                class="hljs-string"
                                style="color: rgb(25 61 245);"
                            >https://partners.primesurveys.com/api/surveys?output=</span><span
                            style="color: rgb(136, 0, 0);"
                        >API</span><span
                            class="hljs-string"
                            style="color: rgb(25 61 245);"
                        >&app=</span><span
                            style="color: rgb(136, 0, 0);"
                        >{{ app_hash }}</span><span
                            class="hljs-string"
                            style="color: rgb(25 61 245);"
                        >&external_user_id=</span><span
                            style="color: rgb(136, 0, 0);"
                        >user-US-1</span><span
                            class="hljs-string"
                            style="color: rgb(25 61 245);"
                        >&ip=</span><span
                            style="color: rgb(136, 0, 0);"
                        >35.151.72.180</span><span
                            class="hljs-string"
                            style="color: rgb(25 61 245);"
                        >&gender=</span><span
                            style="color: rgb(136, 0, 0);"
                        >m</span><span
                            class="hljs-string"
                            style="color: rgb(25 61 245);"
                        >&zip=</span><span
                            style="color: rgb(136, 0, 0);"
                        >32714</span><span
                            class="hljs-string"
                            style="color: rgb(25 61 245);"
                        >&birthday=</span><span
                            style="color: rgb(136, 0, 0);"
                        >1992-02-19</span><span
                            class="hljs-string"
                            style="color: rgb(25 61 245);"
                        >&user_agent=</span><span
                            style="color: rgb(136, 0, 0);"
                        >Mozilla%2F5.0 (Macintosh%3B Intel Mac OS X 10_15_7) AppleWebKit%2F537.36 (KHTML%2C like Gecko) Chrome%2F124.0.0.0 Safari%2F537.36</span>
                        </pre>
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-row class="mb-1">
                <b-col>
                  <div
                    class="offer-info-block p-1"
                    style="border-left-width: 0.357rem !important;"
                  >
                    <b-media no-body>
                      <b-media-aside class="mr-1">
                        <b-avatar
                          size="32"
                          variant="light-primary"
                        >
                          <feather-icon
                            size="16"
                            icon="FilterIcon"
                          />
                        </b-avatar>
                      </b-media-aside>
                      <b-media-body class="my-auto">
                        <div class="offer-info-block-title">
                          API Request Parameters
                        </div>
                        <p
                          class="offer-info-block-des"
                          style="width: auto"
                        >
                          API request parameters are a set of values that are included in the API request.
                          They are used to specify the data you want to send to or receive from the server.
                          The parameters can be categorized into two types 'Required' and 'Optional'.
                          'Required' parameters are essential for the API call to be processed successfully.
                          'Optional' parameters are not mandatory but can be included to provide additional information
                          or customization.
                        </p>
                      </b-media-body>
                    </b-media>
                    <div class="mt-2">
                      <strong>Required:</strong>
                      <table class="table b-table table-striped table-hover border">
                        <thead>
                          <tr>
                            <th>Parameter</th>
                            <th>Type</th>
                            <th>Description</th>
                          </tr>
                        </thead>
                        <tbody role="rowgroup">
                          <tr
                            v-for="(param, index) in integrationRequiredParameters"
                            :key="'required-'+index"
                          >
                            <td>{{ param.key }}</td>
                            <td>{{ param.type }}</td>
                            <td>{{ param.description }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div class="mt-2">
                      <strong>Optional:</strong>
                      <table class="table b-table table-striped table-hover border">
                        <thead>
                          <tr>
                            <th>Parameter</th>
                            <th>Type</th>
                            <th>Description</th>
                          </tr>
                        </thead>
                        <tbody role="rowgroup">
                          <tr
                            v-for="(param, index) in integrationOptionalParameters"
                            :key="'optional-'+index"
                          >
                            <td>{{ param.key }}</td>
                            <td>{{ param.type }}</td>
                            <td>{{ param.description }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </b-tab>
      <b-tab active>
        <template #title>
          <b-media no-body>
            <b-media-aside>
              <b-avatar
                size="32"
                variant="light-primary"
              >
                <feather-icon
                  size="13"
                  icon="BookIcon"
                  class="tab-title-icon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <div class="tab-title-text">
                Offerwall
              </div>
              <div class="tab-subtitle-text mb-0">
                User can choose and complete multiple surveys
              </div>
            </b-media-body>
            <div class="offer-type-recommend">
              Recommended!
            </div>
          </b-media>
        </template>
        <div class="detail-offer-wall">
          <div class="d-flex mb-1">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-avatar
                  size="48"
                  variant="light-primary"
                >
                  <feather-icon
                    size="24"
                    icon="BookIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <div class="offer-info-block-title">
                  Offerwall
                </div>
                <div class="offer-info-block-des">
                  The offerwall shows multiple surveys to the user who can choose and complete many of these
                </div>
              </b-media-body>
            </b-media>
          </div>
          <hr style="border-top: 1px solid #EBE9F1; margin: 1.143rem 0 1.714rem;">
          <b-row>
            <b-col cols="9">
              <b-row class="mb-1">
                <b-col>
                  <div class="block-warning-unique-id">
                    <b-img
                      :src="require('@/assets/images/icons/fi_info.svg')"
                      style="position: absolute;transform: translate(0, -50%);top: 50%; left: 1.214rem; width: 1.214rem;"
                    />
                    <div style="display: inline-block; width: 30.643rem; margin-left: 2.143rem;">
                      <span style="font-weight: bold;">UNIQUE-USER-ID</span> needs to be replaced by a unique user id
                      which is static for the individual user
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-row
                v-if="is_secure"
                class="mb-1"
              >
                <b-col>
                  <div class="block-warning-unique-id">
                    <b-img
                      :src="require('@/assets/images/icons/fi_info.svg')"
                      style="position: absolute;transform: translate(0, -50%);top: 50%; left: 1.214rem; width: 1.214rem;"
                    />
                    <div
                      style="display: block; width: 40.643rem; margin-left: 2.143rem; font-weight: bold; margin-bottom: 1rem"
                    >
                      SECURITY-HASH-SALT: {{ security_hash_salt }}
                    </div>
                    <div style="display: block; width: 40.643rem; margin-left: 2.143rem; margin-bottom: 1rem;">
                      To calculate the <span style="font-weight: bold;">SECURITY-HASH</span>, please use the following
                      formula:
                    </div>
                    <div style="display: block; width: 30.643rem; margin-left: 2.143rem; font-weight: bold">
                      md5(UNIQUE-USER-ID + SECURITY-HASH-SALT)
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-row class="mb-1">
                <b-col>
                  <div
                    class="offer-info-block p-1"
                    style="border-left-width: 0.357rem !important;"
                  >
                    <b-media no-body>
                      <b-media-aside class="mr-1">
                        <b-avatar
                          size="32"
                          variant="light-primary"
                        >
                          <feather-icon
                            size="16"
                            icon="CodeIcon"
                          />
                        </b-avatar>
                      </b-media-aside>
                      <b-media-body class="my-auto">
                        <div class="offer-info-block-title">
                          Script Tag
                        </div>
                        <p class="offer-info-block-des">
                          The script tag will show an icon on the bottom-right of your page which the user can click to
                          open the
                          widget showing the offerwall
                        </p>
                      </b-media-body>
                    </b-media>
                    <div class="code-copy-block">
                      <div
                        class="button-copy bg-primary"
                        @click="copyText(integration_script)"
                      >
                        <span>Copy</span>
                      </div>
                      <div class="code-content bg-light-primary">
                        <pre class="code-block"><span class="hljs-tag">&lt;<span
                          class="hljs-name"
                          style="font-weight: 700;"
                        >script</span> <span class="hljs-attr">type</span>=<span
                          class="hljs-string"
                          style="color: rgb(136, 0, 0);"
                        >"text/javascript"</span>&gt;</span><span class="actionscript">{<span
                          class="hljs-keyword"
                          style="font-weight: 700;"
                        >var</span> primeSurveysConfig = {app: <span
                          class="hljs-string"
                          style="color: rgb(136, 0, 0);"
                        >"{{
                          app_hash
                        }}"</span>, uuid: <span
                          class="hljs-string"
                          style="color: rgb(136, 0, 0);"
                        >"UNIQUE-USER-ID"</span><span v-if="is_secure">, shash: <span
                          class="hljs-string"
                          style="color: rgb(136, 0, 0);"
                        >"SECURITY-HASH"</span></span>, s: <span
                          class="hljs-string"
                          style="color: rgb(136, 0, 0);"
                        >"EXTERNAL-SOURCE-IDENTIFIER"</span>}}
                        </span><span class="hljs-tag">&lt;/<span
                            class="hljs-name"
                            style="font-weight: 700;"
                        >script</span>&gt;</span><br><span
                            class="hljs-tag"
                        >&lt;<span
                            class="hljs-name"
                            style="font-weight: 700;"
                        >script</span> <span class="hljs-attr">src</span>=<span
                            class="hljs-string"
                            style="color: rgb(25, 61, 245);"
                        >"{{ base_url }}/primewidget.js"</span>/&gt;</span><span
                            class="undefined"
                        /><span class="hljs-tag">&lt;/<span
                            class="hljs-name"
                            style="font-weight: 700;"
                        >script</span>&gt;</span></pre>
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-row class="mb-1">
                <b-col>
                  <div
                    class="offer-info-block p-1"
                    style="border-left-width: 0.357rem !important;"
                  >
                    <b-media no-body>
                      <b-media-aside class="mr-1">
                        <b-avatar
                          size="32"
                          variant="light-primary"
                        >
                          <feather-icon
                            size="16"
                            icon="FramerIcon"
                          />
                        </b-avatar>
                      </b-media-aside>
                      <b-media-body class="my-auto">
                        <div class="offer-info-block-title">
                          IFrame
                        </div>
                        <p class="offer-info-block-des">
                          The iFrame can be integrated to a separate page of your project to show the offerwall right
                          there
                        </p>
                      </b-media-body>
                    </b-media>

                    <div class="code-copy-block">
                      <div
                        class="button-copy bg-primary"
                        @click="copyText(integration_iframe)"
                      >
                        <span>Copy</span>
                      </div>
                      <div class="code-content bg-light-primary">
                        <pre class="code-block"><span class="hljs-tag">&lt;<span
                          class="hljs-name"
                          style="font-weight: 700;"
                        >iframe</span> <span class="hljs-attr">src</span>=<span
                          class="hljs-string"
                          style="color: rgb(25 61 245);"
                        >"{{ base_url }}/?app=<span style="color: rgb(136, 0, 0);">{{ app_hash }}</span>&amp;uuid=<span
                          style="color: rgb(136, 0, 0);"
                        >UNIQUE-USER-ID</span><span v-if="is_secure">&amp;shash=<span style="color: rgb(136, 0, 0);">SECURITY-HASH</span></span>&amp;s=<span
                          style="color: rgb(136, 0, 0);"
                        >EXTERNAL-SOURCE-IDENTIFIER</span>"</span>&gt;</span><span class="hljs-tag">&lt;/<span
                          class="hljs-name"
                          style="font-weight: 700;"
                        >iframe</span>&gt;</span></pre>
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-row class="mb-1">
                <b-col>
                  <div
                    class="offer-info-block p-1"
                    style="border-left-width: 0.357rem !important;"
                  >
                    <b-media no-body>
                      <b-media-aside class="mr-1">
                        <b-avatar
                          size="32"
                          variant="light-primary"
                        >
                          <feather-icon
                            size="16"
                            icon="LinkIcon"
                          />
                        </b-avatar>
                      </b-media-aside>
                      <b-media-body class="my-auto">
                        <div class="offer-info-block-title">
                          Direct Link
                        </div>
                        <p class="offer-info-block-des">
                          This link is the same as the iFrame, you can link to it, so it opens in a separate window
                        </p>
                      </b-media-body>
                    </b-media>

                    <div class="code-copy-block">
                      <div
                        class="button-copy bg-primary"
                        @click="copyText(integration_url)"
                      >
                        <span>Copy</span>
                      </div>
                      <div class="code-content bg-light-primary">
                        <pre class="code-block">
                                    <span
                                        class="hljs-string"
                                        style="color: rgb(25 61 245);"
                                    >{{ base_url }}/?app=<span
                                        style="color: rgb(136, 0, 0);"
                                    >{{ app_hash }}</span>&amp;uuid=<span
                                        style="color: rgb(136, 0, 0);"
                                    >UNIQUE-USER-ID</span><span
                                        v-if="is_secure"
                                    >&amp;shash=<span
                                        style="color: rgb(136, 0, 0);"
                                    >SECURITY-HASH</span></span>&amp;s=<span
                                        style="color: rgb(136, 0, 0);"
                                    >EXAMPLE-SOURCE-IDENTIFIER</span>
                                    </span>
                                    </pre>
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="3">
              <img
                style="border: 1px solid #dedede;border-radius: 0.714rem; width: 100%;object-fit: contain;"
                src="@/assets/images/pages/surveywall-2.webp"
              >
            </b-col>
          </b-row>
        </div>
      </b-tab>
      <b-tab>
        <template #title>
          <b-media no-body>
            <b-media-aside class="mr-1">
              <b-avatar
                size="32"
                variant="light-primary"
              >
                <feather-icon
                  size="13"
                  icon="TargetIcon"
                  class="tab-title-icon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <div class="tab-title-text">
                Single Survey
              </div>
              <div
                class="tab-subtitle-text mb-0"
                style="height: 2.429rem"
              >
                Redirect the user to a single survey
              </div>
            </b-media-body>
          </b-media>
        </template>
        <div class="detail-single-survey">
          <div class="d-flex mb-1">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-avatar
                  size="48"
                  variant="light-primary"
                >
                  <feather-icon
                    size="24"
                    icon="TargetIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <b-media-body class="my-auto">
                  <div class="offer-info-block-title">
                    Single Survey
                  </div>
                  <div
                    class="offer-info-block-des"
                    style="width: 36.5rem;"
                  >
                    Single Survey links redirect to a single survey. They have a fixed payout for you and reward the
                    user based on the currency exchange rate
                  </div>
                </b-media-body>
              </b-media-body>
            </b-media>
          </div>
          <hr style="border-top: 1px solid #EBE9F1; margin: 1.143rem 0 1.714rem;">
          <b-row class="mb-1">
            <b-col>
              <div
                class="offer-info-block p-1"
                style="border-left-width: 0.357rem !important;"
              >
                <b-media no-body>
                  <b-media-aside class="mr-1">
                    <b-avatar
                      size="32"
                      variant="light-primary"
                    >
                      <feather-icon
                        size="16"
                        icon="FilterIcon"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <div class="offer-info-block-title">
                      Smart Links
                    </div>
                    <p class="offer-info-block-des">The smart link redirect to a single survey. It serves the optimal
                      and most relevant survey to the user based
                      on
                      a balance between revenue and
                      user experience. Therefore the payout is flexible.</p>
                  </b-media-body>
                </b-media>

                <div class="code-copy-block">
                  <div
                    class="button-copy bg-primary"
                    @click="copyText(integration_url)"
                  >
                    <span>Copy</span>
                  </div>
                  <div class="code-content bg-light-primary">
                    <pre class="code-block">
                            <span
                                class="hljs-string"
                                style="color: rgb(25 61 245);"
                            >{{ base_url }}/?app=<span
                                style="color: rgb(136, 0, 0);"
                            >{{ app_hash }}</span>&amp;uuid=<span
                                style="color: rgb(136, 0, 0);"
                            >UNIQUE-USER-ID</span></span>
                            </pre>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div
                class="offer-info-block p-1"
                style="border-left-width: 0.357rem !important;"
              >
                <b-media no-body>
                  <b-media-aside class="mr-1">
                    <b-avatar
                      size="32"
                      variant="light-primary"
                    >
                      <feather-icon
                        size="16"
                        icon="CopyIcon"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <div class="offer-info-block-title">
                      Single Survey Links
                    </div>
                    <p class="offer-info-block-des">
                      These Single Survey links have fixed payouts for you
                    </p>
                  </b-media-body>
                </b-media>

                <table class="table b-table table-striped table-hover border">
                  <thead>
                    <tr>
                      <th>Payout</th>
                      <th>Url</th>
                    </tr>
                  </thead>
                  <tbody role="rowgroup">
                    <tr
                      v-for="(tr, indextr) in singleSurveyLinks"
                      :key="indextr"
                    >
                      <td style="cursor: pointer;">
                        $ {{ singleSurveyLinks[indextr].payout }}
                      </td>
                      <td>{{ singleSurveyLinks[indextr].url }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
/* eslint-disable no-useless-escape,no-useless-concat */
export default {
  name: 'AppEditIntegration',
  props: [
    'value',
  ],
  data() {
    return {
      integration_script: '',
      integration_iframe: '',
      integration_url: '',
      base_url: null,
      app_hash: null,
      is_secure: false,
      security_hash_salt: null,
      integrationRequiredParameters: [
        { key: '{output}', type: 'String', description: 'Should always be "API"' },
        { key: '{app}', type: 'String', description: 'Your unique App Key' },
        { key: '{external_user_id}', type: 'String', description: 'Your unique user hash or user ID' },
        { key: '{ip}', type: 'String', description: 'IP of the user loading the survey list' },
        {
          key: '{user_agent}',
          type: 'String',
          description: 'User Agent of the user loading the survey list. If unavailable, device is required',
        },
        {
          key: '{device}',
          type: 'String',
          description: 'If User Agent is not available, you can append “Desktop” or “Mobile” with this parameter',
        },
      ],
      integrationOptionalParameters: [
        { key: '{limit}', type: 'Integer', description: 'Defines how many surveys should be returned (max. 50)' },
        { key: '{birthday}', type: 'Date', description: 'Birthday of your user in the format YYYY-MM-DD' },
        { key: '{gender}', type: 'String', description: 'Gender of your user. m = Male, f = Female' },
        { key: '{zip}', type: 'Integer', description: 'Zip Code of your user' },
      ],
    }
  },
  computed: {
    singleSurveyLinks() {
      return [
        {
          payout: 0.25,
          url: `${this.integration_url}&t=1`,
        },
        {
          payout: 0.50,
          url: `${this.integration_url}&t=2`,
        },
        {
          payout: 0.75,
          url: `${this.integration_url}&t=3`,
        },
        {
          payout: 1.00,
          url: `${this.integration_url}&t=4`,
        },
        {
          payout: 1.25,
          url: `${this.integration_url}&t=5`,
        },
        {
          payout: 1.50,
          url: `${this.integration_url}&t=6`,
        },
      ]
    },
    btnGoliveVariant() {
      let isValidReward = false
      if (this.value.reward_type !== 0) isValidReward = true
      else if (this.value.fixed_reward) isValidReward = true
      if (this.value.currency_exchange_rate && this.value.currency_name && isValidReward) return 'primary'
      return 'secondary'
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'value.is_secure': function (newValue) {
      this.is_secure = newValue
      this.updateUrls()
    },
    // eslint-disable-next-line func-names
    'value.security_hash_salt': function (newValue) {
      this.security_hash_salt = newValue
      this.updateUrls()
    },
  },
  mounted() {
    window.AppEditIntegration = this
    this.app_hash = this.value.hash
    this.is_secure = this.value.is_secure
    this.security_hash_salt = this.value.security_hash_salt
    this.base_url = process.env.VUE_APP_SURVEY_BASE_URL
    this.updateUrls()
  },
  methods: {
    requestToMakeAppLive() {
      this.showLoading()
      this.$http
        .post(`/api/app/${this.$route.params.appHash}/request-live`)
        .then(response => {
          const returnData = response.data
          if (returnData.status) {
            this.$emit('request-live', returnData.status)
            this.value.status = returnData.status
          }
        })
        .catch(errors => {
          this.alertError(errors.response.data)
        })
        .finally(() => {
          this.hideLoading()
        })
    },
    onClickRequestGolive() {
      if (this.btnGoliveVariant !== 'secondary') {
        this.requestToMakeAppLive()
      }
    },
    updateUrls() {
      const SURVEY_BASE_URL = this.base_url

      if (!this.value.is_secure) {
        this.integration_url = `${SURVEY_BASE_URL}/?app=${this.value.hash}&uuid=UNIQUE-USER-ID&s=EXAMPLE-SOURCE-IDENTIFIER`
        this.integration_script = `${'<script type="text/javascript">'
            + '{var primeSurveysConfig = {app: "'}${this.value.hash}", uuid: "UNIQUE-USER-ID", s: "EXAMPLE-SOURCE-IDENTIFIER"}}`
            + '<\/script>' + `\n<script src="${SURVEY_BASE_URL}/primewidget.js"/><\/script>`
        this.integration_iframe = `<iframe src="${SURVEY_BASE_URL}/?app=${this.value.hash}&uuid=UNIQUE-USER-ID&s=EXAMPLE-SOURCE-IDENTIFIER"></iframe>`
        return
      }
      this.integration_url = `${SURVEY_BASE_URL}/?app=${this.value.hash}&uuid=UNIQUE-USER-ID&shash=SECURITY-HASH&s=EXAMPLE-SOURCE-IDENTIFIER`
      this.integration_script = `${'<script type="text/javascript">'
          + '{var primeSurveysConfig = {app: "'}${this.value.hash}", uuid: "UNIQUE-USER-ID", shash: "SECURITY-HASH", s: "EXAMPLE-SOURCE-IDENTIFIER"}}`
          + '<\/script>' + `\n<script src="${SURVEY_BASE_URL}/primewidget.js"/><\/script>`
      this.integration_iframe = `<iframe src="${SURVEY_BASE_URL}/?app=${this.value.hash}&uuid=UNIQUE-USER-ID&shash=SECURITY-HASH&s=EXAMPLE-SOURCE-IDENTIFIER"></iframe>`
    },
  },
}
</script>

<style scoped lang="scss">
#app-integration {
  .code-copy-block {
    position: relative;
    background: rgba(115, 103, 240, 0.12);
    border-radius: 0.429rem;
    overflow: hidden;

    & > .button-copy {
      width: 5.571rem;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      color: #FFF;
      text-align: center;
      cursor: pointer;

      span {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
      }
    }

    & > .code-content {
      flex: 1;
      padding: 0.714rem;
      padding-left: 6.286rem;
    }
  }

  .code-block {
    display: block;
    white-space: initial;
    background: none;
    font-size: 1rem;
    margin-bottom: 0;
  }
}

#app-integration .code-copy-block > .code-content > pre > span {
  display: inline !important;
}

.tab-title-text {
  font-style: normal;
  font-weight: 600;
  font-size: 1.143rem;
  line-height: 1.429rem;
}

.tab-subtitle-text {
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 120%;
  width: 18.929rem;
}

.offer-info-block {
  background: #F8F8F8;
  border-radius: 0.429rem;
}

.offer-info-block-title {
  font-weight: 600;
  font-size: 1.071rem;
  line-height: 1.286rem;
  color: #5E5873;
}

.offer-info-block-des {
  width: 35.357rem;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 120%;
  color: #6E6B7B;
}

.block-test-mode {
  background: rgba(234, 84, 85, 0.12);
  border-radius: 0.429rem;
  margin-bottom: 2.5rem;

  .text-test-mode {
    display: inline;
    margin-right: 2rem;
    font-weight: 500;
    font-size: 1rem;
    line-height: 120%;
    color: #EA5455
  }
}

.block-warning-unique-id {
  position: relative;
  background: rgba(234, 84, 85, 0.12);
  border-radius: 0.429rem;
  font-weight: 500;
  font-size: 1rem;
  line-height: 120%;
  color: #EA5455;
  padding: 0.857rem 1.214rem;
}

.offer-type-recommend {
  position: absolute;
  right: 0.714rem;
  color: #fff;
  top: -0.714rem;
  padding: 0.357rem 0.714rem;
  font-weight: 600;
  font-size: 0.857rem;
  line-height: 1.071rem;
  background: #6E6B7B;
  border-radius: 1.429rem;
}

.nav-link.active .offer-type-recommend {
  background: #7367F0;
}
</style>
<style>

[dir] .offer-type .nav-pills .nav-link {
  background: #FFFFFF;
  box-sizing: border-box;
  box-shadow: none;
  padding: 0.857rem;
  border-radius: 0.571rem;
}

[dir] .offer-type .nav-pills .nav-link.active {
  color: #7367F0;
  border: 1px solid #7367F0;
}

[dir] .offer-type.tabs .nav .nav-item {
  margin: 0 0.571rem !important;
  border-radius: 0.714rem !important;
}

svg.tab-title-icon {
  margin-right: 0 !important;
}

.offer-type .nav-link .tab-title-text {
  color: #6E6B7B;
}

.offer-type .nav-link.active .tab-title-text {
  color: #7367F0;
}
</style>
