<template>
  <div id="app_edit_design_tab">
    <b-row v-if="mounted">
      <b-col cols="6">

        <!-- First color -->
        <b-row style="margin-bottom: 1.714rem">
          <b-col cols="5">
            <div class="color-name-title">
              Primary Color
            </div>
            <div class="color-name-des">
              Important texts and icons
            </div>
          </b-col>
          <b-col cols="7">
            <color-picker
              v-model="first_color"
              :color="first_color"
            />
          </b-col>
        </b-row>

        <!-- Second color -->
        <b-row style="margin-bottom: 1.714rem">
          <b-col cols="5">
            <div class="color-name-title">
              Secondary Color
            </div>
            <div class="color-name-des">
              Some buttons and icons
            </div>
          </b-col>
          <b-col cols="7">
            <color-picker
              v-model="second_color"
              :color="second_color"
            />
          </b-col>
        </b-row>

        <!-- Third color -->
        <b-row style="margin-bottom: 1.714rem">
          <b-col cols="5">
            <div class="color-name-title">
              Text Color
            </div>
            <div class="color-name-des">
              Explanations and general text
            </div>
          </b-col>
          <b-col
            cols="2"
            style="border-right: 0.143rem solid #ddd;"
          >
            <color-picker
              v-model="third_color"
              :color="third_color"
            />
          </b-col>
          <b-col
            cols="5"
            style="padding-left: 1.143rem;"
          >
            <span style="line-height: 2.5rem;">We recommend to keep it black</span>
          </b-col>
        </b-row>
        <b-row style="margin-bottom: 1.714rem">
          <b-col cols="5">
            <div class="color-name-title">
              Critical Color
            </div>
            <div class="color-name-des">
              Validation errors, SVG color
            </div>
          </b-col>
          <b-col cols="2">
            <color-picker
              v-model="critical_color"
              :color="critical_color"
            />
          </b-col>
        </b-row>
        <b-row style="margin-bottom: 1.714rem">
          <b-col
            cols="5"
            class="d-flex align-items-center"
          >
            <div class="color-name-title">
              Text Default Color
            </div>
          </b-col>
          <b-col cols="2">
            <color-picker
              v-model="text_default"
              :color="text_default"
              :nullable="true"
            />
          </b-col>
        </b-row>

        <hr style="margin-bottom: 1.714rem">

        <h4 class="font-weight-bolder mb-2">
          Additional Colors
        </h4>
        <!-- Pages background -->
        <b-row style="margin-bottom: 1.714rem">
          <b-col cols="5">
            <div class="color-name-title">
              Pages background
            </div>
            <div class="color-name-des">
              color of the entire background on such pages like survey questions, results, modals with user details, and
              header on the surveys list page
            </div>
          </b-col>
          <b-col cols="7">
            <color-picker
              v-model="pages_bg"
              :color="pages_bg"
            />
          </b-col>
        </b-row>

        <!-- Components background -->
        <b-row style="margin-bottom: 1.714rem">
          <b-col cols="5">
            <div class="color-name-title">
              Components background
            </div>
            <div class="color-name-des">
              background for elements like survey list item, date selector, inputs, dropdowns
            </div>
          </b-col>
          <b-col cols="2">
            <color-picker
              v-model="components_bg"
              :color="components_bg"
            />
          </b-col>
        </b-row>

        <!-- Survey list background -->
        <b-row style="margin-bottom: 1.714rem">
          <b-col
            cols="4"
            class="d-flex align-items-center"
          >
            <div class="color-name-title">
              Survey list background
            </div>
          </b-col>
          <b-col
            cols="1"
            class="d-flex align-items-start"
          >
            <div
              class="form-inline d-flex align-items-start mt-25"
              style="height: 2.857rem;"
            >
              <b-form-checkbox
                v-model="is_custom_surveys_list_bg"
                switch
                class="mr-1"
                :value="1"
                :unchecked-value="0"
              />
            </div>
          </b-col>
          <b-col
            v-if="is_custom_surveys_list_bg"
            cols="2"
          >
            <color-picker
              v-model="surveys_list_bg"
              :color="surveys_list_bg"
            />
          </b-col>
        </b-row>

        <!-- Palette -->
        <b-row style="margin-bottom: 1.714rem">
          <b-col
            cols="4"
            class="d-flex align-items-center"
          >
            <div class="color-name-title">
              <feather-icon
                :icon="palette === 'light' ? 'SunIcon' : 'MoonIcon'"
                size="16"
              />
              Light Palette
            </div>
          </b-col>
          <b-col cols="2">
            <b-form-checkbox
              v-model="palette"
              value="light"
              unchecked-value="dark"
              switch
            />
          </b-col>
        </b-row>

        <!-- Actions Design -->
        <b-row style="margin-bottom: 1.714rem">
          <b-col
            cols="4"
            class="d-flex align-items-center"
          >
            <div class="color-name-title">
              Actions design
            </div>
          </b-col>
          <b-col cols="8">
            <b-form-radio-group
              v-model="actions_bg"
              name="actions_design"
              class="mr-1"
            >
              <b-form-radio value="primary">
                Primary
              </b-form-radio>
              <b-form-radio value="secondary">
                Secondary
              </b-form-radio>
              <b-form-radio value="gradient">
                Gradient
              </b-form-radio>
            </b-form-radio-group>
          </b-col>
        </b-row>

        <hr style="margin-bottom: 1.714rem">

        <b-row class="mb-1">
          <b-col
            class="py-1"
            cols="4"
          >
            <div class="color-name-title">
              Custom Logo
            </div>
          </b-col>
          <b-col cols="8">
            <div
              class="form-inline"
              style="height: 2.857rem;"
            >
              <b-form-checkbox
                v-model="header"
                switch
                class="mr-1"
                :value="1"
                :unchecked-value="0"
              />
            </div>
          </b-col>
          <b-col
            v-if="header"
            cols="9"
          >
            <div
              v-if="design_file != null"
              id="app-design-image"
            >
              <img
                :src="design_file"
                alt="design-file"
              >
            </div>
            <drag-upload-file
              style="width: 36.214rem;"
              :accept="'image/png'"
              @load-file="loadFile($event)"
            >
              Upload file
            </drag-upload-file>
          </b-col>
        </b-row>

        <hr style="margin-bottom: 1.714rem">

        <b-row>
          <b-col
            class="py-1"
            cols="4"
          >
            <div class="color-name-title">
              User income
            </div>
          </b-col>
          <b-col cols="8">
            <div
              class="form-inline"
              style="height: 2.857rem;"
            >
              <b-form-checkbox
                v-model="stats"
                switch
                class="mr-1"
                :value="1"
                :unchecked-value="0"
              />
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            class="py-1"
            cols="4"
          >
            <div class="color-name-title">
              Show Rewards
            </div>
          </b-col>
          <b-col cols="8">
            <div
              class="form-inline"
              style="height: 2.857rem;"
            >
              <b-form-checkbox
                v-model="show_rewards"
                switch
                class="mr-1"
                :value="1"
                :unchecked-value="0"
              />
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            class="py-1"
            cols="4"
          >
            <div class="color-name-title">
              Show Filters
            </div>
          </b-col>
          <b-col cols="8">
            <div
              class="form-inline"
              style="height: 2.857rem;"
            >
              <b-form-checkbox
                v-model="show_filters"
                switch
                class="mr-1"
                :value="1"
                :unchecked-value="0"
              />
            </div>
          </b-col>
        </b-row>

        <hr style="margin-bottom: 1.714rem">

        <b-row>
          <b-col
            class="py-1"
            cols="3"
          >
            <div class="color-name-title">
              Policy URL
            </div>
          </b-col>
          <b-col cols="9">
            <div
              class="w-full"
              style="height: 2.857rem;"
            >
              <b-input
                v-model="policy_url"
              />
            </div>
          </b-col>
        </b-row>

        <hr style="margin-bottom: 1.714rem">

        <b-row class="mb-2">
          <b-col>
            <b-button
              variant="primary"
              @click="changeBackToDefaultColor()"
            >
              Change back to default
            </b-button>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="6">
        <div
          v-for="preview in designPreviews"
          :key="preview.url"
        >
          <label style="font-weight: 500; font-size: 1rem; line-height: 100%; color: #5E5873; margin-bottom: 1.143rem;">
            {{ preview.name }}
          </label>
          <div v-if="isIframeReady">
            <iframe
              ref="designIframe"
              style="border: 1px solid #DBE1E6; box-sizing: border-box; border-radius: 4px;"
              frameborder="0"
              :src="preview.url"
              width="400px"
              height="700px"
            />
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import DragUploadFile from '@ps_main/custom-components/DragUploadFile.vue'
import ColorPicker from '@ps_main/custom-components/ColorPickerInput.vue'

export default {
  components: {
    DragUploadFile,
    ColorPicker,
  },
  props: ['value'],
  data() {
    return {
      first_color: '#164ed0',
      second_color: '#f13870',
      third_color: '#000000',
      critical_color: '#F13870',
      text_default: null,
      upload_design_file: null,
      design_file: null,
      pages_bg: '#ffffff',
      components_bg: '#ffffff',
      surveys_list_bg: null,
      is_custom_surveys_list_bg: 0,
      show_rewards: 0,
      show_filters: 0,
      isIframeReady: false,
      root: null,
      mounted: false,
      header: 0,
      stats: 0,
      palette: 'light',
      policy_url: null,
      actions_bg: 'gradient',
    }
  },
  computed: {
    validateForm() {
      return true
    },
    watchParentValue() {
      return [
        this.value.first_color,
        this.value.second_color,
        this.value.third_color,
        this.value.design_file,
        this.value.currency_name,
        this.value.currency_exchange_rate,
        this.value.critical_color,
        this.value.actions_bg,
      ]
    },
    lastUrlPathRoute() {
      return this.$route.params.appHash
    },
    iframeUrlQuery() {
      return `test=1&col1=${encodeURIComponent(this.first_color)
      }&app=${this.lastUrlPathRoute
      }&header=${encodeURIComponent(this.header)
      }&stats=${encodeURIComponent(this.stats)
      }&col2=${encodeURIComponent(this.second_color)
      }&col3=${encodeURIComponent(this.third_color)
      }&cr_col=${encodeURIComponent(this.critical_color)
      }&txt_def_col=${encodeURIComponent(this.text_default === null ? '' : this.text_default)
      }&img=${encodeURIComponent(
        this.design_file === null ? '' : this.design_file,
      )
      }&cur_name=${encodeURIComponent(this.value.currency_name === null ? '' : this.value.currency_name)
      }&mv_dec=${encodeURIComponent(this.value.move_decimals === null ? '' : this.value.move_decimals)
      }&cur_ex_rate=${encodeURIComponent(this.value.currency_exchange_rate === null ? '' : this.value.currency_exchange_rate)
      }&pages_bg=${encodeURIComponent(this.pages_bg === null ? '' : this.pages_bg)
      }&comp_bg=${encodeURIComponent(this.components_bg === null ? '' : this.components_bg)
      }&srv_list_bg=${encodeURIComponent(this.surveys_list_bg === null ? '' : this.surveys_list_bg)
      }&show_rewards=${encodeURIComponent(this.show_rewards === null ? '' : this.show_rewards)
      }&show_filters=${encodeURIComponent(this.show_filters === null ? '' : this.show_filters)
      }&palette=${encodeURIComponent(this.palette === null ? '' : this.palette)
      }&pol_url=${encodeURIComponent(this.policy_url === null ? '' : this.policy_url)
      }&actions_bg=${encodeURIComponent(this.actions_bg)
      }`
    },
    iframeBaseUrl() {
      const SURVEY_BASE_URL = process.env.VUE_APP_SURVEY_BASE_URL

      return `${SURVEY_BASE_URL}/design-preview`
    },
    iframeUrlSurveys() {
      return `${this.iframeBaseUrl}/surveys?${this.iframeUrlQuery}`
    },
    iframeUrlQualified() {
      return `${this.iframeBaseUrl}/qualify?${this.iframeUrlQuery}`
    },
    iframeUrlControls() {
      return `${this.iframeBaseUrl}/controls?${this.iframeUrlQuery}`
    },
    designPreviews() {
      return [
        {
          name: 'Surveys list preview',
          url: this.iframeUrlSurveys,
        },
        {
          name: 'Survey qualified preview',
          url: this.iframeUrlQualified,
        },
        {
          name: 'Input controls preview',
          url: this.iframeUrlControls,
        },
      ]
    },
  },
  watch: {
    iframeUrlQuery: {
      immediate: true,
      handler(newVal) {
        this.$emit('iFrameURLQueryChanged', newVal)
      },
    },
    first_color() {
      this.syncToParent()
    },
    second_color() {
      this.syncToParent()
    },
    third_color() {
      this.syncToParent()
    },
    design_file() {
      this.syncToParent()
    },
    watchParentValue() {
      this.init_data()
    },
    header() {
      this.syncToParent()
    },
    stats() {
      this.syncToParent()
    },
    critical_color() {
      this.syncToParent()
    },
    pages_bg() {
      this.syncToParent()
    },
    components_bg() {
      this.syncToParent()
    },
    surveys_list_bg() {
      this.syncToParent()
    },
    show_rewards() {
      this.syncToParent()
    },
    show_filters() {
      this.syncToParent()
    },
    palette() {
      this.syncToParent()
    },
    text_default() {
      this.syncToParent()
    },
    policy_url() {
      this.syncToParent()
    },
    actions_bg() {
      this.syncToParent()
    },
    is_custom_surveys_list_bg(val) {
      if (!val) {
        this.surveys_list_bg = null
      }
    },
  },
  mounted() {
    this.init_data()
    this.isIframeReady = true
    this.mounted = true
  },
  methods: {
    changeBackToDefaultColor() {
      this.first_color = '#164ed0'
      this.second_color = '#f13870'
      this.third_color = '#000000'
      this.critical_color = '#F13870'
      this.text_default = null
      this.design_file = null
      this.upload_design_file = null
      this.pages_bg = '#ffffff'
      this.components_bg = '#ffffff'
      this.surveys_list_bg = null
      this.is_custom_surveys_list_bg = 0
      this.show_rewards = 0
      this.show_filters = 0
      this.palette = 'light'
      this.stats = 0
      this.header = 0
      this.policy_url = null
      this.actions_bg = 'gradient'
    },
    loadFile([uploadDesignFile]) {
      if (uploadDesignFile) {
        this.upload_design_file = uploadDesignFile
        this.design_file = URL.createObjectURL(uploadDesignFile)
      }
    },
    reset_data() {
      this.$emit('reset', [
        'first_color',
        'second_color',
        'third_color',
        'critical_color',
        'design_file',
      ])
    },
    init_data() {
      if (
        this.value.first_color !== null
          && this.value.second_color !== null
          && this.value.third_color !== null
      ) {
        this.first_color = `#${this.value.first_color}`
        this.second_color = `#${this.value.second_color}`
        this.third_color = `#${this.value.third_color}`
      }

      this.design_file = this.value.design_file
      if (typeof this.value.upload_design_file === 'object') {
        this.upload_design_file = this.value.upload_design_file
      } else {
        this.upload_design_file = null
      }
      this.header = this.value.header
      this.stats = this.value.stats
      this.critical_color = `#${this.value.critical_color}`
      this.text_default = this.value.text_default ? `#${this.value.text_default}` : null
      this.pages_bg = this.value.pages_bg ? `#${this.value.pages_bg}` : '#ffffff'
      this.components_bg = this.value.components_bg ? `#${this.value.components_bg}` : '#ffffff'
      this.surveys_list_bg = this.value.surveys_list_bg ? `#${this.value.surveys_list_bg}` : null
      this.is_custom_surveys_list_bg = this.value.surveys_list_bg !== null
      this.show_rewards = this.value.show_rewards
      this.show_filters = this.value.show_filters
      this.palette = this.value.palette ?? 'light'
      this.policy_url = this.value.policy_url
      this.actions_bg = this.value.actions_bg ?? 'gradient'
    },
    syncToParent() {
      if (this.mounted) {
        const appData = this.value
        appData.first_color = this.first_color.substring(1)
        appData.second_color = this.second_color.substring(1)
        appData.third_color = this.third_color.substring(1)
        appData.critical_color = this.critical_color.substring(1)
        appData.text_default = this.text_default ? this.text_default.substring(1) : null
        appData.design_file = this.design_file
        appData.upload_design_file = this.upload_design_file
        appData.header = this.header
        appData.stats = this.stats
        appData.pages_bg = this.pages_bg?.substring(1) ?? null
        appData.components_bg = this.components_bg?.substring(1) ?? null
        appData.surveys_list_bg = this.surveys_list_bg?.substring(1) ?? null
        appData.show_rewards = this.show_rewards
        appData.show_filters = this.show_filters
        appData.palette = this.palette
        appData.policy_url = this.policy_url
        appData.actions_bg = this.actions_bg
        this.$emit('input', appData)
      }
    },
  },
}
</script>

<style scoped>
.con-input-upload {
  height: 2.5rem;
  margin: 0;
}

.con-img-upload {
  padding: 0;
}

#app-design-image {
  margin-bottom: 1rem;
  position: relative;
  border: 1px solid #d9e1e6;
  text-align: center;
}

#app-design-image img {
  max-width: 12rem;
  max-height: 12rem;
  margin: auto;
}

#app_edit_design_tab .vc-chrome-toggle-btn {
  display: none !important;
}

#app_edit_design_tab .vc-chrome-fields {
  width: 100%;
}

.color-name-title {
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 100%;
  color: #5E5873;
  margin-bottom: 0.571rem;
}

.color-name-des {
  font-style: normal;
  font-weight: 500;
  font-size: 0.857rem;
  line-height: 100%;
  color: #5E5873;
}
</style>
<style>
.color-picker > span > span {
  border-radius: 0.286rem 0 0 0.286rem;
}
</style>
