<template>
  <div class="app-edit-wizard">
    <form-wizard
      ref="wizard"
      color="#7367F0"
      :title="null"
      :subtitle="null"
      shape="square"
      class="mb-1"
      :start-index="1"
      @on-change="onChangeTab"
    >
      <tab-content title="Add App" />

      <!-- personal details tab -->
      <tab-content title="Finish Setup" />

      <!-- address  -->
      <tab-content title="Integration" />

      <!-- social link -->
      <tab-content title="Review & Testing" />

      <tab-content title="Receive Surveys" />
    </form-wizard>
    <div class="transparent-block" />
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { required } from '@core/utils/validations/validations'

export default {
  name: 'AppEditIntegrationWizard',
  components: {
    FormWizard,
    TabContent,
  },
  props: ['onActive', 'appData'],
  data() {
    return {
      required,
      activeTabIndex: 1,
      maxIndexSuccess: 1,
      isCompleteRewardSetting: false,
      isCompletePostbackSetting: false,
    }
  },
  watch: {
    onActive: {
      handler() {
        if (this.onActive === true) this.checkAndMarkSuccessSteps()
      },
    },
    'appData.status': {
      handler() {
        console.log('appData.status:', this.appData.status)
        this.checkAndMarkSuccessSteps()
      },
    },
  },
  mounted() {
    this.checkAndMarkSuccessSteps()
    window.AppEditIntegrationWizard = this
  },
  methods: {
    formSubmitted() {
      this.alertSuccess('Form Submitted')
    },
    validationIntegration() {
      return new Promise(resolve => {
        resolve(true)
      })
    },
    onChangeTab(oldIndex, newIndex) {
      this.activeTabIndex = newIndex
      if (this.maxIndexSuccess < newIndex) this.maxIndexSuccess = newIndex
    },
    isValidHttpUrl(string) {
      let url

      try {
        url = new URL(string)
      } catch (_) {
        return false
      }

      return url.protocol === 'http:' || url.protocol === 'https:'
    },
    checkAndMarkSuccessSteps() {
      // check complete reward setting
      let isValidReward = false
      if (this.appData && this.appData.reward_type !== 0) isValidReward = true
      else if (this.appData && this.appData.fixed_reward) isValidReward = true

      this.isCompleteRewardSetting = !!(this.appData && this.appData.currency_exchange_rate && this.appData.currency_name && isValidReward)

      // check complete postback setting
      this.isCompletePostbackSetting = !!(this.appData && this.appData.postback_url && this.isValidHttpUrl(this.appData.postback_url))

      if (this.isCompleteRewardSetting && this.isCompletePostbackSetting) {
        while (this.activeTabIndex < 2) {
          this.$refs.wizard.nextTab()
        }
      } else return false

      // check app is test_mode
      if (this.appData && this.appData.status === 0) return false

      while (this.activeTabIndex < 3) {
        this.$refs.wizard.nextTab()
      }

      // If none of the above conditions were met and halted the function execution, we can assume the function was successful.
      return true
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';

.app-edit-wizard {
  position: relative;

  .transparent-block {
    position: absolute;
    z-index: 9;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: transparent;
  }
}

.app-edit-wizard .vue-form-wizard .wizard-navigation .wizard-nav li:not(:first-child) a::before {
  background-image: url('~@/assets/images/icons/right-arrow.svg');
  background-repeat: no-repeat;
  content: "";
  width: 0.714rem;
  height: 1.429rem;
  background-position-y: center;
  filter: invert(73%) sepia(10%) saturate(26%) hue-rotate(202deg) brightness(99%) contrast(97%);
}

.app-edit-wizard .vue-form-wizard .wizard-navigation .wizard-nav li.active a::before {
  filter: invert(35%) sepia(31%) saturate(2970%) hue-rotate(223deg) brightness(110%) contrast(88%);
}

.wizard-card-footer, .wizard-tab-content {
  display: none !important;
}

</style>
